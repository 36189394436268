import React from "react"
import { graphql, Link } from "gatsby"
import "./_CommonShared.scss";
import "./StaticPageLayout.scss";
import { Mdx, FileConnection } from "@GraphQLModels";
import MdxBlock from "components/texty/MdxBlock";
import { childClassClosure } from '@jcharlesworthuk/your-mum-core/dist/functions';
import SiteFooter from "components/headersfooters/SiteFooter";
import { ArticleWaveSvg } from "components/svg";
import ContentPageHeader from "components/headersfooters/ContentPageHeader";
import PageMeta from "components/texty/PageMeta";


type Props = {
  pageContext: {
    id: string,
  };
  data: {
    mdx: Mdx,
  }
}

const baseClass = 'static-page-view';
const childClass = childClassClosure(baseClass);

const StaticPageLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className={baseClass}>
      <PageMeta
          title={props.data.mdx.frontmatter.title}
          description={`${props.data.mdx.frontmatter.title} on ModelShade.com`}
          route={props.data.mdx.fields.route}>
      </PageMeta>
      <ArticleWaveSvg className={childClass('waves')} />
      <ContentPageHeader
        className={childClass('header')}
        title=''
        backArrowRoute='/' />
      <MdxBlock mdx={props.data.mdx}
        className={childClass('section', 'mdx')}
        showTitle={true} />
      <SiteFooter />
    </div>
  )
}

export default StaticPageLayout;

export const pageQuery = graphql`
  query StaticPageLayoutQuery($id: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        timeToRead
        frontmatter {
          title
          subTitle
          mainImage
          standfirst
          date
        }
        body
      }
  }
  `

